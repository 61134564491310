import Navbar from "../components/navbar";

const NotFound = () => {
    
    return (
      <div className="main">
      <Navbar />
      <div className="row">
        <div className="container">
        404 Not found
        </div>
      </div>
    </div>
    );
  };
  
  export default NotFound;