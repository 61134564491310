import Navbar from "../components/navbar";
import Footer from "../components/footer";
import PortfolioItems from "../components/portfolioItem";

const MainPage = () => {
  return (
    <div className="main portfolio">
      <Navbar />
      <div className="mo-intro">
        <div className="row">
          <div className="container">
            <h1>I'm Mo, an experience designer and front-end developer</h1>
          </div>
        </div>
      </div>
      <PortfolioItems />
      
      <Footer />
    </div>
  );
};

export default MainPage;
