import './skeleton.css';
import './App.css';
import MainPage from "./pages/main";
import NotFound from "./pages/404";
import Molonews from "./pages/molonews";
import CollectAI from "./pages/collectAI";
import Foodle from "./pages/foodle";
import About from "./pages/about";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";


const App = () => {
  
  return (
    <Router>
      <Switch>
      <Route exact path="/about" component={About}/>
      <Route exact path="/" component={MainPage}/>
      <Route exact path="/404" component={NotFound}/>
      <Route exact path="/molonews" component={Molonews}/>
      <Route exact path="/collectAI" component={CollectAI}/>
      <Route exact path="/foodle" component={Foodle}/>
      <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

export default App;
