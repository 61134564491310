import Navbar from "../components/navbar";
import Footer from "../components/footer";
import FoodleFlow from "../images/foodle-flow.jpg";
import foodlepic01 from "../images/foodle-process.png";
import Foodledesign01 from "../images/foodle-design01.jpg";
import Foodledesign02 from "../images/foodle-design02.jpg";
import Foodledesign03 from "../images/foodle-design03.jpg";
import Foodledesign04 from "../images/foodle-design04.jpg";
import Foodleint01 from "../images/foodle-int01.gif";
import Foodleint02 from "../images/foodle-int02.gif";

const Foodle = () => {
  return (
    <div className="main portfolio">
      <Navbar />
      <div className="portfolio-cover foodle-cover"></div>
      <div className="container">
        <div className="row">
          <div className="six columns">
            <h4 className="portfolio-section-head">Overview</h4>
            <p className="portfolio-section-text">
              foodle is a startup, helping people finding foods based on their
              taste pattern through AI and by considering different factors such
              as the user's nationality, friend's suggestion, rating and etc.
            </p>
          </div>
          <div className="six columns">
            <h4 className="portfolio-section-head">My role</h4>
            <ul>
              <li>UX/UX design</li>
              <li>Iterative prototyping</li>
              <li>Visual element Design</li>
              <li>Usability tests</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <h4 className="portfolio-section-head">Identify</h4>
          <p className="portfolio-section-text">
            When usually people travel to a new area have some problem by
            unfamiliar restaurants and foods. Also finding new foods and
            restaurants which serve food close to your taste is difficult and
            time-consuming. foodle offers restaurants by considering the user's
            nationality, friend's suggestion, food rating. Trying to match the
            user's pattern in ordering foods and restaurants around.
          </p>
          <p className="portfolio-section-text">
            First, as a solo UX designer with the business team, I started
            defining the design strategy. The startup was in the early-stage and
            gathering important data relating to the business was important.
          </p>
          <p className="portfolio-section-image text-center">
            <img src={foodlepic01} alt="identify foodle" />
          </p>
        </div>
        <div className="row">
          <h4 className="portfolio-section-head">Research & Analysis</h4>
          <p className="portfolio-section-text">
            First, I interviewed the founders to find the goal and course of
            action and studied the business plan to have deeper insight. we
            started the process and trying to consider the end users factors
            while searching in an area for new foods. During user research, we
            got lots of feedback from user's survey form. The feedback was
            valuable and it has been used in designs to sort and display
            elements in a view and user navigation in the app.
          </p>
          <p className="portfolio-section-image">
            <img src={Foodledesign02} alt="foodle design" />
          </p>
          <p className="portfolio-section-text">
            In brainstorming sessions, we started ideating and documenting
            problems-solutions. We gathered all the related data from workshop
            interviews, surveys, and researches. We also ask our workshop
            participants to give feedback and fill our surveys out. We received
            valuable feedback from them and start the wireframing.
          </p>
          <p className="portfolio-section-image text-center">
            <img src={Foodledesign01} alt="identify foodle" />
          </p>
        </div>
        <div className="row">
          <h4 className="portfolio-section-head">
            User Navigation and Architecture
          </h4>
          <p className="portfolio-section-text">
            After doing user research and brainstorming about the problems,
            challenges and providing solutions. I started creating the structure
            of app and determine tasks and views. I created user flow and this
            is like a map for all teams involving in the project and could be
            helpful to structure the project.
          </p>
          <p className="portfolio-section-image">
            <img src={FoodleFlow} alt="foodle design" />
          </p>
        </div>
        <div className="row">
          <h4 className="portfolio-section-head">Design and Prototype</h4>
          <p className="portfolio-section-text">
            The next step after clarifying the user journey was thinking about
            design and manage the visual elements of the project. I started
            wireframing and visual design and tried to use appropriate color
            based on some researches and users' feedback.
          </p>
          <p className="portfolio-section-image">
            <img src={Foodledesign03} alt="foodle design" />
          </p>
          <p className="portfolio-section-image">
            <img src={Foodledesign04} alt="foodle design" />
          </p>
          <p className="portfolio-section-text">
          Interaction parts also is applied the interactions on
          the user interface, the transitions make the
          User experience livelier.
          </p>
          <p className="portfolio-section-image">
            <img className="img-gif" src={Foodleint01} alt="foodle design" />
            <img className="img-gif" src={Foodleint02} alt="foodle design" />
          </p>
        </div>
        <div className="row">
          <h4 className="portfolio-section-head">Usability Test</h4>
          <p className="portfolio-section-text">
            Providing the interactive gives us the opportunity to run a
            usability test and get feedback from users. We used Think-aloud
            method and got feedback from users by asking them to explain their
            experience during working with the prototype.
          </p>
          <p className="portfolio-section-text">
            I divided the gathered data from participants into different
            categories and used them to find problems and improve the prototype.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Foodle;
