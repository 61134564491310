

const Footer = () => {
  return (
      <div className="footer">
        <div className="row">
          <div className="container">
              <hr />
            <h5>Let's Get In Touch!</h5>
            <p>Ready to start a project with me? contact me and I will get back to you as soon as possible!</p>
            <ul className="footer-links">
                <li><a href="mailto:mohamadz@gmail.com">Email</a></li>
                <li><a href="https://www.linkedin.com/in/mohamadz-salehi/">Linkedin</a></li>
                <li><a href="https://dribbble.com/mohamadz">Dribbble</a></li>
            </ul>
          </div>
        </div>
      </div>
  );
};

export default Footer;
