import Navbar from "../components/navbar";
import Footer from "../components/footer";
import CAIDesign01 from "../images/cai-design01.jpg";
import CAIDesign02 from "../images/cai-design02.jpg";
import CAIDesign03 from "../images/cai-design03.jpg";
import CAIDesign04 from "../images/cai-design04.jpg";
import CAIDesign05 from "../images/cai-design05.jpg";
import CAIDesign06 from "../images/cai-design06.jpg";
import CAIDesign07 from "../images/cai-design07.jpg";
import CAIDesign08 from "../images/cai-design08.jpg";

const CollectAI = () => {
  return (
    <div className="main portfolio">
      <Navbar />
      <div className="portfolio-cover collectAI-cover"></div>
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p className="data-note">
              <span>! </span>Some designs and contents have been changed in
              order to protect collectAI data!
            </p>
          </div>
        </div>

        <div className="row">
          <div className="six columns">
            <h4 className="portfolio-section-head">Overview</h4>
            <p className="portfolio-section-text">
              CollectAI is an Intelligent Payment Solution Provider. Offering a
              software platform for Interactive Invoices and Smart Payment
              Reminders. <br />I worked on the cost saver web app which is
              designed particularly for the sales team and potential clients of
              collectAI. I worked as a UX designer and front-end developer with
              the sales and marketing team.
            </p>
          </div>
          <div className="six columns">
            <h4 className="portfolio-section-head">My role</h4>
            <ul>
              <li>Information Architecture</li>
              <li>Iterative prototyping and testing</li>
              <li>Visual and interaction design</li>
              <li>Front-end development</li>
              <li>partly Back-end development</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <h4 className="portfolio-section-head">Identify</h4>
          <p className="portfolio-section-text">
            collectAI Cost saver is an internal app for the sales team to
            present collectAI possibilities for potential clients. The aim of
            this web-based app sales team and clients can calculate the cost of
            sending claims with and without collectAI services. In the end, they
            can choose the right services based on their custom settings and
            budget. They realize the saving potential each month.
          </p>
          <p className="portfolio-section-image text-center">
            <img src={CAIDesign03} alt="identify cai" />
          </p>
          <p className="portfolio-section-text">
            I started the interview sessions with the sales and marketing team
            as the main users of the app. The complexity of the previous version
            in detail was noted, and I started designing wireframes and
            prototyping based on the feedback.
          </p>
          <p className="portfolio-section-image">
            <img src={CAIDesign02} alt="cai design" />
          </p>
        </div>
        <div className="row">
          <h4 className="portfolio-section-head">Structure and Architecture</h4>
          <p className="portfolio-section-text">
            One of the common UX challenges in the fintech sector is users
            always dealing with so many numbers and most of the time following
            numbers and determine them is not easy. The cost-saver also was the
            world of numbers and those blocks of numbers needed to be separated
            into subsections.
          </p>
          <p className="portfolio-section-text">
            Too many blocks of numbers could make the user confused. We
            categorized them and improve the architecture information by
            defining steps more clear and well separated.
          </p>
          <p className="portfolio-section-image">
            <img src={CAIDesign08} alt="CAI design" />
          </p>
          <p className="portfolio-section-text">
            To avoid user distraction blocks have been separated differently,
            when the user is in a specific section the related block to that
            section will be activated with bolder colors and other blocks are
            shown with less opacity. This can help users to concentrated and
            view the result of their inputs immediately, faster, and more clear.
            In addition to seeing the input effects more properly, increments
            and decrement indicator has been added.
          </p>
          <p className="portfolio-section-image">
            <img src={CAIDesign05} alt="CAI  design" />
          </p>
          <p className="portfolio-section-text">
            One of the key challenges was to adjust the inputs and related
            results. Users needed to see the calculations and have a good
            overview after applying the optimization on their inputs. Besides
            displaying the overall overview of other costs was needed to be
            considered.
          </p>
          <p className="portfolio-section-image">
            <img src={CAIDesign04} alt="CAI design" />
          </p>
        </div>
        <div className="row">
          <h4 className="portfolio-section-head">Prototyping and Feedbacks</h4>
          <p className="portfolio-section-text">
            I documented all the considered feedback from our internal teams and
            potential clients. The documents and gathered data from the feedback
            sessions and interviews used in an iterative process to create the
            prototypes and in the final designs.
          </p>
          <p className="portfolio-section-image">
            <img src={CAIDesign07} alt="CAI  design" />
          </p>
          <p className="portfolio-section-text">
            The general overview helps users to see every detail and final
            calculation. The visual elements used in the design divide block
            numbers and avoid distraction. The calculations can be followed
            easily and interaction, in the end, can be done easily in the case
            some information is missing or needs to be edited.
          </p>
          <p className="portfolio-section-image">
            <img src={CAIDesign06} alt="CAI  design" />
          </p>
          <p className="portfolio-section-text">
            At the end of each iteration, our sales and marketing team applied
            new feedbacks, and we had discussions in order to determine the
            possible changes and new improvements.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CollectAI;
