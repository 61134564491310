import Navbar from "../components/navbar";
import Footer from "../components/footer";
import IdentifyFlow from "../images/Identify-flow.png";
import MoloDesign01 from "../images/molo-design01.png";
import MoloDesign04 from "../images/molo-design04.png";
import MoloDesign02 from "../images/molo-design02.jpg";
import MoloDesign03 from "../images/molo-design03.jpg";

const Molonews = () => {
  return (
    <div className="main portfolio">
      <Navbar />
      <div className="portfolio-cover molonews-cover"></div>
      <div className="container">
        <div className="row">
          <div className="six columns">
            <h4 className="portfolio-section-head">Overview</h4>
            <p className="portfolio-section-text">
              molo.news is the new app for the Bremen metropolitan area. Daily
              news and events from the region are published as molos! <br />
              molo.news is created in “co-creation”, i.e. together with
              interested users. The goal is creating an offer by many for many.
              Thus, molo.news app offers new possibilities for social
              participation and contribute to an improved, common urban public.
            </p>
          </div>
          <div className="six columns">
            <h4 className="portfolio-section-head">My role</h4>
            <ul>
              <li>Conducting UX research through co-creation workshops</li>
              <li>Analyzing user feedbacks</li>
              <li>Iterative prototyping</li>
              <li>Designing UI, and improving user experience</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <h4 className="portfolio-section-head">Identify</h4>
          <p className="portfolio-section-text">
            At molo.news, many new molos are released daily, in other words news
            and events from regional media providers, which are provided with
            titles, teasers and pictures in a news feed. this content reaches
            the molo.news editorial via various rss feeds of the media and is
            curated and published by an editorial staff. particularly important
            (hot) news is curated in such a way that it reaches all users of the
            app. besides the molos.news creates content of regionally based
            collective (eg sports clubs, social movements, etc.). these molos
            are set directly by the collective and published after reviewing the
            editorial.
          </p>
          <p className="portfolio-section-image text-center">
            <img src={IdentifyFlow} alt="identify molo" />
          </p>
          <p className="portfolio-section-text">
            Users can filter news and events based on their interest and see
            them on their main feed. News should be sortable by user and get
            effected by changing location.
          </p>
          <p className="portfolio-section-image">
            <img src={MoloDesign01} alt="molo news design" />
          </p>
        </div>
        <div className="row">
          <h4 className="portfolio-section-head">User vision Discovery</h4>
          <p className="portfolio-section-text">
            The idea of regionally based content needed getting feedback from
            users of different ages and this matter opens an opportunity in
            conducting users research to have an optimal user experience besides
            getting feedback about the idea. Therefore, after preparing the
            first sketches and wireframes, our team organized the first
            co-creation workshops.
          </p>
          <p className="portfolio-section-image">
            <img src={MoloDesign02} alt="molo news design" />
          </p>
          <p className="portfolio-section-text">
            Our team organized different co-creation workshops with participants
            at different ages (12-17 & 22-40). The advantage of a co-creation
            workshop is participants have a great mind without any boundary and
            we could gather lots of feedbacks to iterate prototype design
            process and improve the design.
          </p>
        </div>
        <div className="row">
          <h4 className="portfolio-section-head">Prototyping and design</h4>
          <p className="portfolio-section-text">
            We had a discussion session after each workshop, taking about
            feedbacks from users, possible features and problems and trying to
            iterate and improve the prototype for next workshops and developers.
          </p>
          <p className="portfolio-section-image">
            <img src={MoloDesign03} alt="molo news design" />
          </p>
          <p className="portfolio-section-text">
            After improving the design and passing early stages of design, we
            ran workshops more purposeful and select some UX points to get
            feedback from users. For example, As you can see on the below images
            I designed a single view article in three different way and ask
            participants to get feedback from them.
          </p>
          <p className="portfolio-section-image">
            <img src={MoloDesign04} alt="molo news design" />
          </p>
          <p className="portfolio-section-text">
            Results from workshops helped us in iterating and I started
            designing and preparing interactive prototype. In this state, from
            one side getting feedback from workshop participants for improving
            the prototype and on the other side, I should consider the Usability
            structures and technical matters.
          </p>
          
          <p className="portfolio-section-text">
            Moreover, I started applying interactivity to prototype to provide a
            better prospect for both workshop participants and developers. As
            you can see one of the interactions on each card after swiping.
          </p>
        </div>
      </div>
      <Footer />
    </div>
    
  );
};

export default Molonews;
