import { Link } from "react-router-dom";
import logo from "../images/logo.svg";
const Navbar = () => {
  return (
    <div className="navbar">
      <div className="container">
        <div className="navbar-logo">
          <img className="logo" src={logo} alt="logo" />
        </div>
        <ul className="navbar-list">
          <li className="navbar-item">
            <Link className="nav-link about-link" to="/about">
              About
            </Link>
          </li>
          <li className="navbar-item">
            <Link className="nav-link main-link" to="/">
              Portfolio
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
