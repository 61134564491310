import Navbar from "../components/navbar";
import Footer from "../components/footer";
import MoPic from "../images/mo-pic.png";

const About = () => {
  return (
    <div className="main about">
      <Navbar />
      <div className="about-me">
        <div className="container">
          <div className="row">
            <div className="eight columns">
              <h1>My name is Mohamad Salehi</h1>
              <p className="about-intro-text">
                I'm a UX designer and front-end developer and focused on
                optimizing digital experiences on and beyond the screen (VR/AR,
                Voice).
              </p>
            </div>
            <div className="four columns">
              <p className="portfolio-section-image text-right">
                <img src={MoPic} alt="mo ux designer" />
              </p>
            </div>
          </div>
          <div className="row">
          <p className="portfolio-section-text"></p>
          <p className="portfolio-section-text">
            Currently, I'm working at collectAI as a UX designer and frontend
            developer to improve the landing pages' experience and developing
            internal web apps. Before, I was working with the MoloNews team as a
            UI/UX designer.
          </p>
          <p className="portfolio-section-text">
            I hold a Bachelor's degree in Information Technology Engineering and
            studied MSc. in Digital Media at the University of Bremen in
            cooperation with the University of Art. The engineering background
            in combination with design helps me to have a better understanding
            during a problem-solving process and to provide solutions based on
            the users' needs, business goals, and technical capabilities. I've
            experienced working at both B2B and B2C companies in different
            sectors (E-commerce, Fintech, and Media) and I'm always curious to
            learn more in both design and development.
          </p>
          
        </div>
          <div className="row">
            <div className="six columns">
              <h4 className="portfolio-section-head">Design</h4>
              <p className="portfolio-section-text">
              <ul>
                <li>Defining UX Strategy</li>
                <li>Conducting UX research</li>
                <li>UI Design</li>
                <li>Rapid interactive prototyping</li>
                <li>Usability testing</li>
              </ul>
              </p>
            </div>
            <div className="six columns">
              <h4 className="portfolio-section-head">Development</h4>
              <ul>
                <li>HTML + CSS</li>
                <li>JavaScript</li>
                <li>React</li>
                <li>Swift (IOS apps Prototyping)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
