import React, { useState } from "react";
import { Link } from "react-router-dom";
import UXIcon from "../images/UX-icon.svg";
import WebIcon from "../images/web-icon.svg";
import MobileIcon from "../images/mobile-icon.svg";
import DevelopmentIcon from "../images/development-icon.svg";

const PortfolioItem = () => {
  const [portfolio] = useState([
    {
      title: "MoLo.News",
      description:
        "molo.news is the new app for the Bremen metropolitan area. Daily news and events from the region are published as molos",
      link: "/molonews",
      tumbClass: "molo-tumb",
      tags: [{icon: MobileIcon, name: "Mobile"}, {icon: UXIcon, name: "UX"}]
    },
    // {
    //   title: "collectAI",
    //   description:
    //     "CollectAI is an Intelligent Payment Solution Provider. Offering a software platform for Interactive Invoices and Smart Payment Reminders.",
    //   link: "/collectAI",
    //   tumbClass: "collectAI-tumb",
    //   tags: [{icon: WebIcon, name: "Web"}, {icon: UXIcon, name: "UX"}, {icon: DevelopmentIcon, name: "Development"}]
    // },
    {
      title: "foodle",
      description:
        "foodle is a startup that focuses on finding foods based on people taste and orders.",
      link: "/foodle",
      tumbClass: "foodle-tumb",
      tags: [{icon: MobileIcon, name: "Mobile"}, {icon: UXIcon, name: "UX"}]
      
    },
  ]);

  return (
    <div className="row">
      <div className="container">
        {portfolio.map((item, i) => (
          <div className="portfolio-item row" key={i}>
            <div className="five columns">
              <div className={"portfolio-tumb " + item.tumbClass}></div>
            </div>
            <div className="seven columns">
              <h3 className="portfolio-item-title">{item.title}</h3>
              <p className="portfolio-item-text">{item.description}</p>
              <ul className="portfolio-tags-lists">
                {item.tags.map((tag,i) => (
                  <li key={i}><span><img src={tag.icon} alt={tag.name} /></span> {tag.name}</li>
                ))}
              </ul>
              <p className="portfolio-item-link">
                <Link to={item.link}>View More</Link>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortfolioItem;
